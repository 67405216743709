'use client'

import { clsx } from 'clsx'
import type { HTMLAttributes, ReactNode } from 'react'
import { useId } from 'react'
import { get, useFormContext } from 'react-hook-form'
import { getClassNames } from '../../config'

export type Option = {
  label: ReactNode
  value: string
  children?: ReactNode
  className?: string
}

type Props = {
  name: string
  description?: string
  options: Option[]
  label: string
  autoComplete?: string
  required?: boolean
} & HTMLAttributes<HTMLFieldSetElement>

export function RadioGroupField({
  label,
  name,
  options,
  autoComplete,
  required,
  ...attributes
}: Props) {
  const id = useId()
  const errorId = `${id}:error`
  const classes = getClassNames('checkout').form

  const { register, formState, watch } = useFormContext()
  const error = get(formState.errors, name) as { message: string } | undefined
  const invalid = Boolean(error?.message && formState.submitCount > 0)
  const input = register(name)
  const value = watch(name)

  return (
    <fieldset
      {...attributes}
      aria-invalid={invalid}
      data-name={name}
      data-required={required}
      className={clsx(classes.control, attributes.className)}
    >
      <legend id={`${id}-label`}>{label}</legend>

      <div className={classes.options}>
        {options.map((option, i) => (
          <div key={option.value} className={classes.option}>
            <input
              {...input}
              id={`${id}-${i}`}
              type="radio"
              value={option.value}
              autoComplete={autoComplete}
            />
            <div>
              <label htmlFor={`${id}-${i}`} className={option.className}>
                {option.label}
              </label>

              {option.value === value && option.children ? (
                <div className={classes.subFields}>{option.children}</div>
              ) : undefined}
            </div>
          </div>
        ))}
      </div>

      {invalid && (
        <div id={errorId} className={classes.error} role="alert" aria-live="polite">
          {error?.message}
        </div>
      )}
    </fieldset>
  )
}
