import Money from '../components/Money'
import { getClassNames } from '../config'
import { useProduct } from '../hooks/use-product'
import { getTokenFromCookie } from '../lib/auth'

export type Props = {
  sku: string
  discountedPriceCents?: string
}

function PriceWidget({ sku, discountedPriceCents }: Props) {
  const classes = getClassNames('price')

  const { product } = useProduct(sku)
  const prices = product?.prices
  const price = prices?.[0]

  const token = getTokenFromCookie()

  if (!token || !price?.currency_code) {
    return null
  }

  const hasPromotionApplied = Boolean(
    price.compare_at_amount_cents &&
      price.compare_at_amount_cents !== price.amount_cents &&
      !discountedPriceCents
  )

  return (
    <span className={classes.root}>
      {discountedPriceCents && (
        <Money
          className={classes.current}
          centAmount={Number(discountedPriceCents)}
          currency={price.currency_code}
        />
      )}
      {price.amount_cents && (
        <Money
          className={discountedPriceCents ? classes.compare : classes.current}
          centAmount={price.amount_cents}
          currency={price.currency_code}
        />
      )}

      {hasPromotionApplied && (
        <Money
          className={classes.compare}
          centAmount={price.compare_at_amount_cents}
          currency={price.currency_code}
        />
      )}
    </span>
  )
}

export default PriceWidget
