import { useId } from 'react'
import { get, useFormContext, UseFormReturn } from 'react-hook-form'
import { getClassNames, getTranslations } from '../../config'
import { ListOption } from '../../lib/shipping-method'
import { ToggleField } from '../form/ToggleField'
import { AddressFields } from './AddressFields'
import { ShippingFormValues } from './CheckoutShipment'

type Props = {
  options: ListOption[]
  form: UseFormReturn<any>
}

const name = 'methodId'

export const HomeDeliveryChooser = ({ options, form }: Props) => {
  const translations = getTranslations('checkout')
  const classes = getClassNames('checkout').stepShipping
  const formClasses = getClassNames('checkout').form

  const { register, formState, watch } = useFormContext<ShippingFormValues>()
  const input = register(name)
  const error = get(formState.errors, name) as { message: string } | undefined
  const id = useId()
  const errorId = `${id}:error`

  const differentAddress = watch('differentAddress')

  return (
    <>
      <ol className={classes.homeDeliveryChooser}>
        {options.map((option, index) => (
          <li key={option.id}>
            <input
              {...input}
              id={`option-${option.id}`}
              type="radio"
              value={option.id}
              aria-errormessage={errorId}
              checked={index === 0}
            />
            <label htmlFor={`option-${option.id}`}>
              {option.timeFrom} - {option.timeTo}
            </label>
          </li>
        ))}
      </ol>

      <h3>{translations.stepShipping.shippingTitle}</h3>
      <ToggleField label={translations.stepShipping.shipToDifferent} name="differentAddress" />
      {differentAddress && <AddressFields addressType="shippingAddress" form={form} />}

      {error?.message && formState.submitCount > 0 && (
        <div id={errorId} className={formClasses.error} role="alert" aria-live="polite">
          {error.message}
        </div>
      )}
    </>
  )
}
