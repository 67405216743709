import { AdvancedMarker, Map, useMap } from '@vis.gl/react-google-maps'
import { clsx } from 'clsx'
import { Fragment, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { getClassNames, getTranslations } from '../../config'
import { CENTER_OF_NETHERLANDS } from '../../lib/google-maps'
import { LocationOption } from '../../lib/shipping-method'
import { ShippingFormValues } from './CheckoutShipment'

const MARKER_OFFSET = 0.045

type Props = {
  options: LocationOption[]
  name: 'methodId'
  id: string
}

export const PickupPointMap = ({ options, name, id }: Props) => {
  const classes = getClassNames('checkout').stepShipping.dealerChooser
  const translations = getTranslations('checkout').stepShipping.dealerChooser

  const { register, watch } = useFormContext<ShippingFormValues>()
  const input = register(name)
  const value = watch(name)

  const [selectedOption, setSelectedOption] = useState<LocationOption>()

  const map = useMap()

  return (
    <Map
      mapId={id}
      defaultZoom={7}
      maxZoom={10}
      minZoom={7}
      defaultCenter={CENTER_OF_NETHERLANDS}
      disableDefaultUI
      zoomControl
    >
      {options.map((option) => {
        const isOpen = selectedOption === option
        const onToggle = (isOpen: boolean) => setSelectedOption(isOpen ? option : undefined)

        return (
          <Fragment key={option.id}>
            <AdvancedMarker
              onClick={() => {
                onToggle(!isOpen)
                map?.panTo({
                  lat: option.address?.latitude + MARKER_OFFSET,
                  lng: option.address?.longitude,
                })
                map?.setZoom(10)
              }}
              position={{ lat: option.address?.latitude, lng: option.address?.longitude }}
              title={option.name}
            >
              <div className={clsx(classes.mapMarker, value === option.id && classes.selected)} />
            </AdvancedMarker>

            {isOpen && (
              // This is a infowindow made with the use of AdvancedMarker.
              // We use the AdvancedMarker to create a custom infowindow because the default infoWidow can not be styled.
              <AdvancedMarker
                // We need to set the onClick handler to enable pointer-events for the radio button and label.
                onClick={() => {}}
                position={{
                  lat: option.address?.latitude + MARKER_OFFSET,
                  lng: option.address?.longitude,
                }}
                className={classes.mapInfoWindow}
                zIndex={1}
              >
                <input
                  {...input}
                  id={`map-option-${option.id}`}
                  type="radio"
                  value={option.id}
                  checked={value === option.id}
                />
                <label htmlFor={`map-option-${option.id}`}>
                  <address>
                    <strong>{option.name}</strong>
                    <div>
                      {option.address?.street} {option.address?.houseNumber}
                      <br />
                      {option.address?.postalCode}, {option.address?.city}
                    </div>
                  </address>

                  <span aria-hidden>
                    {option.id === value ? translations.selected : translations.select}
                  </span>
                </label>
              </AdvancedMarker>
            )}
          </Fragment>
        )
      })}
    </Map>
  )
}
