import { Address } from '@commercelayer/sdk'
import useSWR from 'swr'
import { getTokenFromCookie } from '../lib/auth'
import { ShippingMethodsResponse } from '../lib/shipping-method'
import { Metadata } from './use-order'

/**
 * Returns the shipping methods from DeliveryMatch.
 */
export const useShippingMethods = (orderId?: string, zipcode?: string | null) => {
  const token = getTokenFromCookie()?.access_token
  const { data: shippingMethods, ...rest } = useSWR(
    token && orderId && zipcode ? { key: 'shippingMethods', orderId, token, zipcode } : undefined,
    ({ orderId, token, zipcode }) => fetchShippingMethods(orderId, token, zipcode),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    }
  )

  // Filter out shipping methods of type 'location' that do not have lat/long.
  const filteredShippingMethods = shippingMethods?.shipmentMethods.map((method) => {
    if (method.type === 'location') {
      return {
        ...method,
        options: method.options.filter(
          (option) => option.address?.latitude && option.address?.longitude
        ),
      }
    }
    return method
  })

  return { shippingMethods: filteredShippingMethods, ...rest }
}

const fetchShippingMethods = async (orderId: string, token: string, location: string) => {
  if (!window.commerceConfig.shippingMethodsApi?.baseUrl) {
    throw new Error('Dealer API not configured')
  }

  return fetch(
    `${window.commerceConfig.shippingMethodsApi.baseUrl}/logistics_api/insert_shipment_proxy/`,
    {
      method: 'POST',
      body: JSON.stringify({ orderId, location }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  ).then<ShippingMethodsResponse>((data) => data.json())
}

export const orderMetadataToClDealerAddress = (metadata: Metadata | null): Address => ({
  city: metadata?.method_city ?? '',
  country_code: metadata?.method_country ?? '',
  company: metadata?.method_name,
  line_1: metadata?.method_street ?? '',
  line_2: metadata?.method_house_number,
  phone: '',
  zip_code: metadata?.method_postal_code,
  state_code: '',
  created_at: '',
  id: '',
  updated_at: '',
  type: 'addresses',
})
