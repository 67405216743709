import { Order } from '@commercelayer/sdk'
import { yupResolver } from '@hookform/resolvers/yup'
import { APIProvider } from '@vis.gl/react-google-maps'
import { useEffect, useMemo, useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { getClassNames, getTranslations } from '../../config'
import { Metadata, useCurrentOrder } from '../../hooks/use-order'
import { FormAddress, formAddressToApiAddress, isSameAddress } from '../../lib/address'
import {
  ListShippingMethod,
  LocationShippingMethod,
  ShippingMethod,
  ShippingMethodId,
} from '../../lib/shipping-method'
import { Form } from '../form/Form'
import { Option, RadioGroupField } from '../form/RadioGroupField'
import Money from '../Money'
import { HomeDeliveryChooser } from './HomeDeliveryChooser'
import { PickupPointChooser } from './PickupPointChooser'
import { getShippingMethodSchema } from './schema'
import { useEstimatedShippingCost } from './ShippingCost'

export type ShippingFormValues = {
  shippingMethod: ShippingMethodId
  differentAddress: boolean
  shippingAddress?: FormAddress
  methodId?: string
}

type Props = {
  deliveryMatchId: string
  shippingMethods: ShippingMethod[]
  onSubmit: (order: Order) => void
}

export const CheckoutShipment = ({ deliveryMatchId, shippingMethods, onSubmit }: Props) => {
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const { order, updateOrder } = useCurrentOrder()
  const translations = getTranslations('checkout')
  const classes = getClassNames('checkout')
  const { setEstimatedShippingCost } = useEstimatedShippingCost()

  const schema = useMemo(
    () => getShippingMethodSchema(translations, shippingMethods),
    [order, shippingMethods]
  )

  const selectedShippingMethod: ShippingMethodId = order?.metadata?.shipping_method

  const form = useForm<ShippingFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      shippingMethod: selectedShippingMethod ?? shippingMethods[0].id,
      differentAddress: Boolean(
        selectedShippingMethod === 'home-delivery' &&
          order?.billing_address &&
          order.shipping_address &&
          !isSameAddress(order.billing_address, order.shipping_address)
      ),
      methodId: order?.metadata?.method_id,
    },
    mode: 'onChange',
  })

  const shippingMethodField = form.watch('shippingMethod')

  const hasDealerWarning = shippingMethods.length === 1 && shippingMethods[0].id === 'dealer-pickup'

  useEffect(() => {
    form.clearErrors('methodId')

    const shippingMethod = shippingMethods.find((method) => method.id === shippingMethodField)
    setEstimatedShippingCost(shippingMethod?.priceInCents ?? 0)
  }, [shippingMethodField])

  return (
    <>
      {hasDealerWarning && (
        <div className={classes.stepShipping.dealerWarning} role="alert" aria-live="polite">
          {translations.stepShipping.dealerWarningMessage}
        </div>
      )}

      <Form
        form={form}
        translations={translations.stepShipping}
        onSubmit={async (values) => {
          if (!order) return

          const method = shippingMethods.find((method) => method.id === values.shippingMethod)
          if (!method) return

          const metadata = toOrderMetadata(values, method, deliveryMatchId)
          const commerceLayerShippingMethodId = shippingMethods.find(
            (method) => method.id === values.shippingMethod
          )?.commerceLayerId

          return updateOrder(
            {
              id: order.id,
              ...(values.shippingMethod === 'home-delivery'
                ? { _shipping_address_same_as_billing: !values.differentAddress }
                : {}),
            },
            {
              shippingMethodId: commerceLayerShippingMethodId,

              shippingAddressUpdate:
                values.differentAddress &&
                values.shippingAddress &&
                values.shippingMethod === 'home-delivery'
                  ? formAddressToApiAddress(values.shippingAddress)
                  : undefined,

              metadata,
            }
          ).then((order) => {
            if (order) {
              onSubmit(order)
            }
          })
        }}
      >
        <RadioGroupField
          label={translations.stepShipping.shippingMethod}
          name="shippingMethod"
          options={shippingMethods.map((method) =>
            toShippingMethod(method, scriptLoaded, setScriptLoaded, form)
          )}
        />
      </Form>
    </>
  )
}

const toShippingMethod = (
  method: ShippingMethod,
  scriptLoaded: boolean,
  setScriptLoaded: (value: boolean) => void,
  form: UseFormReturn<any>
) => {
  switch (method.type) {
    case 'list':
      return toHomeDeliveryMethod(method, form)
    case 'location':
      return toPickupDeliveryMethod(method, scriptLoaded, setScriptLoaded)
  }
}

const toHomeDeliveryMethod = (method: ListShippingMethod, form: UseFormReturn<any>): Option => ({
  value: method.id,
  className: 'home',
  label: (
    <>
      <strong>{method.name}</strong>
      <small>{method.deliveryLeadTime}</small>
      <Money centAmount={method.priceInCents} currency={method.currency} />
      <span></span>
    </>
  ),
  children: <HomeDeliveryChooser options={method.options} form={form} />,
})

const toPickupDeliveryMethod = (
  method: LocationShippingMethod,
  scriptLoaded: boolean,
  setScriptLoaded: (value: boolean) => void
): Option => ({
  value: method.id,
  className: method.id === 'dealer-pickup' ? 'dealer' : 'pickup',
  label: (
    <>
      <strong>{method.name}</strong>
      <small>{method.deliveryLeadTime}</small>
      <Money centAmount={method.priceInCents} currency={method.currency} />
      <span></span>
    </>
  ),
  children: (
    <APIProvider
      apiKey={window.commerceConfig.googleMapsApiKey}
      libraries={['places']}
      onLoad={() => setScriptLoaded(true)}
    >
      {scriptLoaded && <PickupPointChooser options={method.options} />}
    </APIProvider>
  ),
})

const toOrderMetadata = (
  values: ShippingFormValues,
  method: ShippingMethod,
  deliveryMatchId: string
): Metadata => {
  if (!isLocationMethod(method)) {
    const option = method?.options.find((option) => option.id === values.methodId)
    return {
      deliveryMatchId,
      shipping_method: values.shippingMethod,
      method_id: option?.id ?? '',
    }
  } else {
    const option = method?.options.find((option) => option.id === values.methodId)
    return {
      deliveryMatchId,
      shipping_method: values.shippingMethod,
      method_id: option?.id ?? '',
      method_name: option?.name,
      method_city: option?.address?.city,
      method_country: option?.address?.country,
      method_house_number: option?.address?.houseNumber,
      method_postal_code: option?.address?.postalCode,
      method_street: option?.address?.street,
    }
  }
}

const isLocationMethod = (method: ShippingMethod): method is LocationShippingMethod =>
  method.type === 'location'
