import { ApiError, LineItem, Sku } from '@commercelayer/sdk'
import { useEffect, useState } from 'react'
import Markdown from 'react-markdown'
import CartSkeleton from '../components/CartSkeleton'
import Money from '../components/Money'
import { PriceTable } from '../components/PriceTable'
import { useEstimatedShippingCost } from '../components/checkout/ShippingCost'
import { getClassNames, getTranslations } from '../config'
import { useClient } from '../hooks/use-commerce-layer'
import { useCurrentOrder } from '../hooks/use-order'
import { fireEvent, removeFromCartEvent, viewCartEvent } from '../lib/analytics'
import { range } from '../lib/collection'
import AvailabilityWidget from './AvailabilityWidget'

type StockQuantityError = {
  availableQuantity: number
  lineItem: LineItem
}

function CartWidget() {
  const classes = getClassNames('cart')
  const lineItemsClasses = getClassNames('lineItems')
  const translations = getTranslations('cart')
  const checkoutURL = window.commerceConfig?.urls.checkout

  const [stockQuantityError, setStockQuantityError] = useState<StockQuantityError>()
  const { order, updateLineItemQuantity, isLoading } = useCurrentOrder()
  const client = useClient()

  const { estimatedShippingCost } = useEstimatedShippingCost()

  useEffect(() => {
    if (order) {
      fireEvent(viewCartEvent(order))

      for (const lineItem of order.line_items?.filter((l) => l.item_type === 'skus') ?? []) {
        client.line_items
          .update({
            id: lineItem.id,
            quantity: lineItem.quantity,
          })
          .catch((error) => {
            const quantityError = (error as ApiError).errors?.find(
              (e) =>
                e.source.pointer === '/data/attributes/quantity' &&
                e.meta.error === 'less_than_or_equal_to'
            )
            if (quantityError) {
              setStockQuantityError({
                availableQuantity: quantityError.meta.count,
                lineItem,
              })
              updateLineItemQuantity(lineItem, Number(quantityError.meta.count))
            } else {
              throw error
            }
          })
      }
    }
  }, [Boolean(order)])

  if (isLoading) {
    return <CartSkeleton />
  }

  if (!order?.line_items?.length) {
    return <Markdown className={classes.empty}>{translations.emptyCartMessage}</Markdown>
  }

  const outOfStockError = order.line_items
    ?.filter((l) => l.item_type === 'skus')
    .some(
      (lineItem) => lineItem.item && ((lineItem.item as Sku).inventory as any)?.available === false
    )

  const hasDealerWarning = order.line_items
    .map((line) => line.item)
    .filter(isSku)
    .some(
      (sku) =>
        sku.shipping_category?.id === window.commerceConfig.shippingCategories.dealerRequiredItems
    )

  return (
    <div className={classes.root}>
      {stockQuantityError?.lineItem.name && (
        <div className={classes.quantityError} role="alert" aria-live="polite">
          {translations.quantityErrorMessage
            .replace('{article}', stockQuantityError.lineItem.name)
            .replace('{quantity}', stockQuantityError.availableQuantity.toString())}
        </div>
      )}

      {outOfStockError && (
        <div className={classes.outOfStockError} role="alert" aria-live="polite">
          {translations.outOfStockErrorMessage}
        </div>
      )}

      {hasDealerWarning && (
        <div className={classes.dealerWarning} role="alert" aria-live="polite">
          {translations.dealerWarningMessage}
        </div>
      )}

      <ul className={classes.lineItems}>
        {order.line_items
          ?.filter((l) => l.item_type === 'skus')
          .map((lineItem) => (
            <li key={lineItem.id}>
              <figure>
                <img
                  src={lineItem.image_url ?? ''}
                  width={203}
                  height={152}
                  alt={lineItem.name ?? ''}
                />
                {lineItem.discount_cents !== 0 && (
                  <span className={lineItemsClasses.badge}>{translations.discountLabel}</span>
                )}
              </figure>

              <div className={classes.lineItemsContent}>
                <div className={classes.lineItemsHeader}>
                  <strong>{lineItem.name}</strong>

                  <button
                    className={classes.lineItemsRemove}
                    onClick={() => {
                      setStockQuantityError(undefined)
                      updateLineItemQuantity(lineItem, 0)
                      fireEvent(removeFromCartEvent(lineItem))
                    }}
                  >
                    <span>{translations.removeLineItem}</span>
                  </button>
                </div>
                <div className={classes.lineItemsBody}>
                  <div className={classes.selectContainer}>
                    <select
                      className={classes.select}
                      title={lineItem.name ?? ''}
                      value={lineItem.quantity}
                      onChange={(event) => {
                        setStockQuantityError(undefined)
                        updateLineItemQuantity(lineItem, Number(event.target.value)).catch(
                          (error) => {
                            const quantityError = (error as ApiError).errors?.find(
                              (e) =>
                                e.source.pointer === '/data/attributes/quantity' &&
                                e.meta.error === 'less_than_or_equal_to'
                            )
                            if (quantityError) {
                              setStockQuantityError({
                                availableQuantity: quantityError.meta.count,
                                lineItem,
                              })
                              updateLineItemQuantity(lineItem, Number(quantityError.meta.count))
                            } else {
                              throw error
                            }
                          }
                        )
                      }}
                    >
                      {range(10).map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>

                  {lineItem.total_amount_cents && lineItem.discount_cents !== 0 ? (
                    <div className={lineItemsClasses.priceCompare}>
                      <Money
                        centAmount={lineItem.total_amount_cents}
                        currency={order.currency_code!}
                        className={lineItemsClasses.priceOld}
                      />

                      {lineItem.discount_cents && (
                        <Money
                          centAmount={lineItem.discount_cents + lineItem.total_amount_cents}
                          currency={order.currency_code!}
                          className={lineItemsClasses.price}
                        />
                      )}
                    </div>
                  ) : (
                    lineItem.total_amount_cents && (
                      <Money
                        centAmount={lineItem.total_amount_cents}
                        currency={order.currency_code!}
                        className={lineItemsClasses.price}
                      />
                    )
                  )}
                </div>
              </div>

              {lineItem.sku_code && (
                <AvailabilityWidget
                  sku={lineItem.sku_code}
                  translations={translations.availability}
                />
              )}
            </li>
          ))}
      </ul>

      <div className={classes.aside}>
        <div className={classes.costOverview}>
          <PriceTable order={order} estimatedShippingCost={estimatedShippingCost} />

          <small>{translations.taxInformation}</small>

          {!outOfStockError ? (
            <a className={classes.proceedButton} href={checkoutURL}>
              {translations.proceedCheckout}
            </a>
          ) : (
            <button className={classes.proceedButton} disabled>
              {translations.proceedCheckout}
            </button>
          )}
        </div>

        {translations.paymentDescription && (
          <div className={classes.paymentDescription}>
            <Markdown
              components={{
                a: ({ node, ...rest }) => <a target="_blank" rel="noreferrer" {...rest} />,
              }}
            >
              {translations.paymentDescription}
            </Markdown>
          </div>
        )}
      </div>
    </div>
  )
}

export default CartWidget

const isSku = (value: any): value is Sku => value.type === 'skus'
