import { ShippingMethod as CTShippingMethod } from '@commercelayer/sdk'

// TODO: check if we still need this function
export const shippingMethodType = (shippingMethod: CTShippingMethod) =>
  shippingMethod.metadata?.allow_alternative_delivery_address === 'true'
    ? ('home' as const)
    : // TODO: fix this bad check (metadata?)
    shippingMethod.name.toLowerCase().includes('dealer')
    ? ('dealer' as const)
    : undefined

export type ShippingMethodBase = {
  name: string
  id: ShippingMethodId
  commerceLayerId: string
  carrier?: string
  priceInCents: number
  currency: string
  deliveryLeadTime: string
}

export type LocationShippingMethod = ShippingMethodBase & {
  type: 'location'
  options: LocationOption[] // Address is required for 'location'
}

export type ListShippingMethod = ShippingMethodBase & {
  type: 'list'
  options: ListOption[] // Address is optional for 'list'
}

export type ShippingMethod = LocationShippingMethod | ListShippingMethod

export type OptionBase = {
  id: string
  currency: string
}

export type LocationOption = OptionBase & {
  name: string
  address: Address // Address is required
}

export type ListOption = OptionBase & {
  timeFrom?: string
  timeTo?: string
}

export type ShippingMethodId = 'home-delivery' | 'dealer-pickup' | 'courier-pickup'

export type Address = {
  street: string
  houseNumber: string
  city: string
  postalCode: string
  country: string
  latitude: number
  longitude: number
}

export type ShippingMethodsResponse = {
  shipmentMethods: ShippingMethod[]
}
